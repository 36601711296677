import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

// modules
import organization from './organization'

export default new Vuex.Store({
  modules: {
    organization
  },
  strict: process.env.DEV
})
