<template>
  <div class="callback">
    <div class="d-flex justify-content-center align-items-center vh-100">
      <div class="d-flex align-items-center justify-content-center flex-column">
        <b-spinner variant="primary" label="Text Centered" />
        <div class="waiting mt-1">Logging in...</div>
      </div>
    </div>
  </div>
</template>

<script>
import router from '@/router'
import store from '@/store'
import { onMounted } from 'vue'
import { OIDC_STORE_MODULE } from '../script'

export default {
  name: 'OidcCallback',
  setup() {
    onMounted(() => {
      store
        .dispatch(`${OIDC_STORE_MODULE}/oidcSignInCallback`)
        .then((redirectPath) => {})
        .catch((err) => {
          console.error(err)
          router.push('/oidc-callback-error')
        })
    })
  }
}
</script>
