<template>
  <div class="d-none">Login screen</div>
</template>

<script>
import store from '@/store'
import { isUserLoggedIn } from '@/utils'
import { OIDC_STORE_MODULE } from '../script'

export default {
  setup() {
    if (!isUserLoggedIn()) {
      store.dispatch(`${OIDC_STORE_MODULE}/authenticateOidc`)
    }
  }
}
</script>
