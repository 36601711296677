import router from '@/router'
import store from '@/store'
import { getUserData } from '@/utils'
import { showToast } from '@nodus/utilities-front'
import decode from 'jwt-decode'
import jwtDefaultConfig from './jwtDefaultConfig'

export default class JwtService {
  // Will be used by this service for making API calls
  axiosIns = null

  accessToken = null

  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // For Refreshing Token
  subscribers = []

  constructor(axiosIns, jwtOverrideConfig) {
    this.axiosIns = axiosIns
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      (config) => {
        // Get token from localStorage
        this.accessToken = this.getToken()

        // If token is present add it to request's Authorization Header
        if (this.accessToken) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${this.accessToken}`
        }
        return config
      },
      (error) => Promise.reject(error)
    )

    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      (response) => {
        const {
          status,
          data: { message }
        } = response

        if (response && message) {
          // eslint-disable-next-line no-constant-condition
          showToast(status === 200 || 201 ? 'success' : 'error', message)
        }

        return response
      },
      (error) => {
        const { config, response } = error
        const originalRequest = config
        const { status, data } = response

        if (error.code === 'ERR_CANCELED') return

        if (status === 403) {
          router.push('/error/403')
          return
        }

        const handledStatusCodes = [400, 401, 404, 409, 500, 503]
        const isCodeHandled = handledStatusCodes.some((code) => code === status)
        if (!isCodeHandled || data.message) {
          showToast('error', data.message || 'Something went wrong!')
          return
        }

        if (status && status !== 401) {
          router.push(`/error/${status}`)
          return
        }

        if (response && status === 401) {
          const { exp } = decode(this.accessToken)
          if (!this.isAlreadyFetchingAccessToken) {
            if (Date.now() < exp * 1000) {
              router.push('/error/401')
              return
            }
            this.isAlreadyFetchingAccessToken = true
            this.refreshToken().then((r) => {
              this.isAlreadyFetchingAccessToken = false
              this.onAccessTokenFetched(r.access_token)
            })
          }
          const retryOriginalRequest = new Promise((resolve) => {
            this.addSubscriber((accessToken) => {
              // Change Authorization header
              originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
              resolve(this.axiosIns(originalRequest))
            })
          })
          return retryOriginalRequest
        }
        return Promise.reject(error)
      }
    )
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter((callback) =>
      callback(accessToken)
    )
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
    return getUserData()?.access_token
  }

  getRefreshToken() {
    return getUserData()?.refresh_token
  }

  login(...args) {
    return this.axiosIns.post(this.jwtConfig.loginEndpoint, ...args)
  }

  register(...args) {
    return this.axiosIns.post(this.jwtConfig.registerEndpoint, ...args)
  }

  /* eslint-disable class-methods-use-this */
  refreshToken() {
    return store.dispatch('oidcStore/authenticateOidcSilent')
  }
}
