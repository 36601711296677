import { isUserLoggedIn } from '@/utils'
import { Ability } from '@casl/ability'
import { abilitiesPlugin } from '@casl/vue'
import Vue from 'vue'

//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this
const existingAbility = isUserLoggedIn()
  ? [{ action: 'manage', subject: 'all' }]
  : null

const initialAbility = [
  {
    action: 'read',
    subject: 'Auth'
  }
]

const ability = new Ability(existingAbility || initialAbility)

Vue.use(abilitiesPlugin, ability)

export default ability
