import axiosIns from '@/axios'

export default {
  namespaced: true,
  state: {
    id: '',
    plan: ''
  },
  getters: {
    plan: (state) => state.plan,
    id: (state) => state.id
  },
  actions: {
    async getDetails({ commit }, id) {
      const response = await axiosIns.get(`/api/v1/organizations/${id}`)

      commit('SET_PLAN', response?.data?.data?.organization?.plan)
      commit('SET_ID', response?.data?.data?.organization?.code)
    }
  },
  mutations: {
    SET_ID(state, organization) {
      state.id = organization
    },
    SET_PLAN(state, plan) {
      state.plan = plan
    }
  }
}
