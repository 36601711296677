import axiosIns from '@/axios'
import JwtService from './jwtService'

function useJwt(axiosIns, jwtOverrideConfig) {
  return new JwtService(axiosIns, jwtOverrideConfig)
}

const jwt = useJwt(axiosIns, {})

export default jwt
