const userInfoIState = {
  access_token: '',
  expires_at: 0,
  id_token: '',
  profile: {
    birthdate: '',
    gender: '',
    preferred_username: '',
    given_name: '',
    family_name: '',
    language_code: '',
    name: '',
    role: [],
    sub: '',
    picture: '',
    email: ''
  }
}

export const getUserData = () => {
  let userData = userInfoIState
  const _user = window.localStorage.getItem(
    `oidc.user:${process.env.APP_AUTHORITY}:${process.env.CLIENT_ID}`
  )
  if (_user) return JSON.parse(_user)
  else return userData
}

export const isUserLoggedIn = () => !!getUserData()?.access_token
