var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "callback" }, [
    _c(
      "div",
      {
        staticClass: "d-flex justify-content-center align-items-center vh-100",
      },
      [
        _c(
          "div",
          {
            staticClass:
              "d-flex align-items-center justify-content-center flex-column",
          },
          [
            _c("b-spinner", {
              attrs: { variant: "primary", label: "Text Centered" },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "waiting mt-1" }, [
              _vm._v("Logging in..."),
            ]),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }