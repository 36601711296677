import { getUserData } from '@/utils'
import axios from 'axios'

const axiosIns = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  headers: {
    'Access-Control-Allow-Origin': '*',
    Authorization: `Bearer ${getUserData()?.access_token}`
  }
})

export default axiosIns
