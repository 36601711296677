import useJwt from '@/auth/jwt/useJwt'
import router from '@/router'
import store from '@/store'
import { vuexOidcCreateStoreModule } from 'vuex-oidc'
import ability from '../ability'

export const OIDC_STORE_MODULE = 'oidcStore'

export const oidcSettings = JSON.parse(process.env.VUE_APP_OIDC_CONFIG)
oidcSettings.monitorSession = false
oidcSettings.clockSkew = 3156000000
oidcSettings.silentRedirectUri = null
oidcSettings.checkSessionInterval = Math.min()

export const oidcStoreModule = vuexOidcCreateStoreModule(
  oidcSettings,
  // NOTE: If you do not want to use localStorage for tokens, in stead of just passing oidcSettings, you can
  // spread your oidcSettings and define a userStore of your choice
  // {
  //   ...oidcSettings,
  //   userStore: new WebStorageStateStore({ store: window.sessionStorage })
  // },
  // Optional OIDC store settings
  {
    namespaced: true,
    dispatchEventsOnWindow: true
  },
  // Optional OIDC event listeners
  {
    userLoaded: async (user) => {
      if (user?.access_token === '') {
        logoutUser('auth-login')
        return
      }

      ability.update([{ action: 'manage', subject: 'all' }])

      const isCallback = window.location.pathname === '/callback'

      if (isCallback) {
        router
          .push(sessionStorage.getItem('vuex_oidc_active_route') || '/')
          .catch(() => {})
      }
    },
    userUnloaded: () => console.log('OIDC user is unloaded'),
    accessTokenExpiring: () => {
      console.log('Access token will expire')
      useJwt.refreshToken()
    },
    accessTokenExpired: () => {
      console.log('Access token did expire')
      console.log('calling renew Token...')
      useJwt.refreshToken()
    },
    silentRenewError: () => {
      console.log('OIDC user is unloaded')
      logoutUser('auth-login')
    },
    userSignedOut: () => {
      console.log('OIDC user is signed out')
      logoutUser('auth-login')
    },
    oidcError: (payload) => {
      console.log('OIDC error', payload)
      // authConfig.oidcErrorHook(payload)

      if (payload && payload.error === 'invalid_grant') {
        logoutUser('auth-login')
      }
    },
    automaticSilentRenewError: (payload) => {
      console.log('OIDC automaticSilentRenewError', payload)
      logoutUser('auth-login')
    }
  }
)

export async function logoutUser() {
  // Wait for logout to complete
  await store.dispatch(`${OIDC_STORE_MODULE}/signOutOidcSilent`)

  // Reset ability
  ability.update([{ action: 'manage', subject: 'all' }])

  // Redirect to login page
  router.push('/login')
}

if (store) {
  store.registerModule(OIDC_STORE_MODULE, oidcStoreModule)
}
